import React from 'react';
import coin from "../assets/vote_gold.png";
import burger from "../assets/boosters/hat.png";
import vote_gold from "../assets/nav/rocket.png";
import energy_drink from "../assets/boosters/energy_drink.png";
import rage_mode from "../assets/boosters/rage_mode.png";
import tie from "../assets/boosters/tie.png";

import bg from "../assets/bg.png";

import './HomeRoadmap.css'

const Roadmap = ({info = false}) => {
    return (
        <div className={`${info ? 'info' : ''} home-roadmap-wrapper`}>


            <img className={`${info ? 'info' : ''} home-roadmap-bg`} src={bg} />


            {
                info ?
                    ''
                    :
                    <h1 className={'header banner-btn'}>
                        ROADMAP
                    </h1>
            }


            <div className={'home-roadmap-container'}>

                <img className={'roadmap-floating-coin'} src={coin} />
                <img className={'roadmap-floating-coin'} src={coin} />
                <img className={'roadmap-floating-coin'} src={coin} />
                <img className={'roadmap-floating-coin'} src={coin} />
                <img className={'roadmap-floating-coin'} src={coin} />

                <div className={'home-roadmap-item'}>

                    <div className={'home-roadmap-item-left'}>
                        <h1>
                            SEASON I
                        </h1>

                        <img src={burger} />
                    </div>

                    <div className={'home-roadmap-item-right'}>

                        <div className={'home-roadmap-item-right-part'}>
                            TrumPump Tap Game
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            New Earn Tasks
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            Wallet Integration
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            NEW Card Collection
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            Ratings
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            New Gameplay Mechanics
                        </div>



                        <div className={'home-roadmap-item-right-part'}>
                            Additions
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            Daily Countersign
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            PUMP Organization
                        </div>

                    </div>

                </div>





                <div className={'home-roadmap-item'}>

                    <div className={'home-roadmap-item-left'}>
                        <h1>
                            SEASON II
                        </h1>

                        <img src={energy_drink} />
                    </div>

                    <div className={'home-roadmap-item-right'}>

                        <div className={'home-roadmap-item-right-part'}>
                            Trumponomics Development v1.0
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold}/>
                            Token launch
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold}/>
                            Collaborations and Partnerships
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold}/>
                            Token listings
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold}/>
                            Airdrop
                        </div>


                        <div className={'home-roadmap-item-right-part'}>
                            CryptoTrump NFT Collection
                        </div>


                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold}/>
                            NFT collection release
                        </div>

                    </div>

                </div>

                <div className={'home-roadmap-item'}>

                    <div className={'home-roadmap-item-left'}>
                    <h1>
                        SEASON III
                        </h1>

                        <img src={rage_mode}/>
                    </div>

                    <div className={'home-roadmap-item-right'}>

                        <div className={'home-roadmap-item-right-part'}>
                            TrumPump Metaverse
                        </div>


                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            Public DEMO
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            BETA test
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            RELEASE
                        </div>

                    </div>

                </div>







                <div className={'home-roadmap-item'}>

                    <div className={'home-roadmap-item-left'}>
                        <h1>
                            SEASON IV
                        </h1>

                        <img src={tie} />
                    </div>

                    <div className={'home-roadmap-item-right'}>

                        <div className={'home-roadmap-item-right-part'}>
                            Trumponomics development v2.0
                        </div>


                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            Algorithmic stablecoin
                        </div>

                        <div className={'home-roadmap-item-right-item'}>
                            <img src={vote_gold} />
                            DEX
                        </div>

                    </div>

                </div>
            </div>

            {
                info ?
                    <p className={'home-bottom-p'}>
                        <br/>
                        Trumpump was created to support the true Crypto President, Donald Trump. The project has not
                        been coordinated with or endorsed by Donald Trump and was made by a group of like-minded
                        developers. We share a common vision for financial future and provide grassroots support.
                        <br/>
                        <br/>
                        Contact us: pr@trumpump.app
                        <br/>
                        © 2024 Trumpump. All rights reserved.
                    </p>
                    :
                    ''
            }

        </div>
    );
};

export default Roadmap;